import * as d3 from 'd3';
import {customColors} from './visualizationFlags';
import { getDisplayName } from '../../utils/utils';

// Function to create SVG
export const createSVG = (svgRef, dimensions, margin) => {
  const svg = d3.select(svgRef.current);
  svg.selectAll('*').remove();

  // Calculate total width and height including margins
  const width = dimensions.width + margin.left + margin.right;
  const height = dimensions.height + margin.top + margin.bottom;

  // Set the viewBox and preserveAspectRatio for responsiveness
  svg
    .style('width', '100%')    // Make SVG responsive to container
    .style('height', '100%')   // Same here
    .style('background-color', 'black')
    .style('border-radius', '10px');

  return svg;
};

// Helper function to format numbers
const formatNumber = (d) => {
  if (d >= 1e9) return (d / 1e9).toFixed(1) + "B"; // Billion
  if (d >= 1e6) return (d / 1e6).toFixed(1) + "M"; // Million
  if (d >= 1e3) return (d / 1e3).toFixed(1) + "k"; // Thousand
  return d.toString(); // Smaller numbers remain unchanged
};

// Function to create X Axis
export const createXAxis = (svg, data, xAxisColumn, xAxisType, dimensions, margin, grid) => {
  let xScale;
  const xAxisLabel = getDisplayName(xAxisColumn);

  // Dynamically calculate max label length based on available height of the Y axis
  const axisHeight = dimensions.width + margin.left + margin.right;
  const charWidthEstimate = 7; // Estimate average character width in pixels (depends on font)
  const maxLabelLength = Math.floor(axisHeight / charWidthEstimate);
   const truncatedLabel = xAxisLabel.length > maxLabelLength
     ? xAxisLabel.slice(0, maxLabelLength) + '...'
     : xAxisLabel;

  if (xAxisType === 'double') {
    const extent = d3.extent(data, d => d[xAxisColumn]);
    xScale = d3.scaleLinear()
      .domain([extent[0], extent[1]])
      .range([margin.left, dimensions.width + margin.left])
      .nice();
  } else if (xAxisType === 'string') {
    xScale = d3.scaleBand()
      .domain(data.map(d => d[xAxisColumn]))
      .range([margin.left, dimensions.width + margin.left])
      .padding(0.1);
  }

  const xAxis = d3.axisBottom(xScale);

  if (xAxisType !== 'string') {
    xAxis.ticks(5);
  }

  if (xAxisType === 'double') {
    xAxis.ticks(5).tickFormat(d => formatNumber(d));
  }

  const xAxisGroup = svg.append("g")
    .attr("transform", `translate(0, ${margin.top + dimensions.height})`)
    .call(xAxis)
    .style("font-size", "10px")
    .style("color", "#F9FAFB");

  // Check number of ticks and rotate labels if more than 6 ticks
  const tickCount = xAxisGroup.selectAll('.tick').size();
  if (tickCount > 6) {
    xAxisGroup.selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-0.8em")
      .attr("dy", "0.15em")
      .attr("transform", "rotate(-30)");
  }

  // X-axis label
  svg.append("text")
    .attr("x", margin.left + (dimensions.width / 2))
    .attr("y", margin.top + dimensions.height + 40)
    .style("text-anchor", "middle")
    .text(xAxisLabel)
    .style("font-size", "12px")
    .style("font-weight", "bold")
    .style("fill", "#F9FAFB");

  // Optional grid lines
  if (grid) {
    const xAxisGrid = d3.axisBottom(xScale)
      .tickSize(-dimensions.height)
      .tickFormat('');

    const gridGroup = svg.append("g")
      .attr("class", "x-axis-grid")
      .attr("transform", `translate(0, ${margin.top + dimensions.height})`)
      .call(xAxisGrid)
      .style("color", "#D7D7D7")
      .style("stroke-dasharray", "2,2")
      .style("opacity", "0.3");
  }

  return { svg, xScale };
};


// Function to create Y Axis
export const createYAxis = (svg, data, yAxisColumn, yAxisType, dimensions, margin, grid) => {
  let yScale;
  const yAxisLabel = getDisplayName(yAxisColumn);

  // Dynamically calculate max label length based on available height of the Y axis
  const axisHeight = dimensions.height + margin.top + margin.bottom;
  const charWidthEstimate = 7; // Estimate average character width in pixels (depends on font)
  const maxLabelLength = Math.floor(axisHeight / charWidthEstimate);
   const truncatedLabel = yAxisLabel.length > maxLabelLength
     ? yAxisLabel.slice(0, maxLabelLength) + '...'
     : yAxisLabel;

  if (yAxisType === 'double') {
    const extent = d3.extent(data, d => d[yAxisColumn]);
    const bufferPercentage = 0;
    const buffer = (extent[1] - extent[0]) * bufferPercentage;

    yScale = d3.scaleLinear()
      .domain([extent[0] - buffer, extent[1] + buffer])
      .range([dimensions.height + margin.top, margin.top])
      .nice();
  } else if (yAxisType === 'string') {
    yScale = d3.scaleBand()
      .domain(data.map(d => d[yAxisColumn]))
      .range([dimensions.height + margin.top, margin.top])
      .padding(0.1);
  } else if (yAxisType === 'date') {
    yScale = d3.scaleTime()
      .domain(d3.extent(data, d => new Date(d[yAxisColumn])))
      .range([dimensions.height + margin.top, margin.top]);
  }

  const yAxis = d3.axisLeft(yScale);

  if (yAxisType !== 'string') {
    yAxis.ticks(5);
  }
  if (yAxisType === 'double') {
    yAxis.ticks(5).tickFormat(d => formatNumber(d));
  } else if (yAxisType === 'date') {
    yAxis.tickFormat(d3.timeFormat("%Y-%m-%d"));
  }

  const yAxisGroup = svg.append("g")
    .attr("transform", `translate(${margin.left}, 0)`)
    .call(yAxis)
    .style("font-size", "10px")
    .style("color", "#F9FAFB");

    // Y-axis label with truncation and tooltip
    const yAxisText = svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", margin.left - 50)
      .attr("x", - (margin.top + (dimensions.height / 2)))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("font-weight", "bold")
      .style("fill", "#F9FAFB")
      .text(truncatedLabel);

    // Add a tooltip that shows the full label on hover
    if (yAxisLabel.length > maxLabelLength) {
      yAxisText
        .append("title") // Tooltip to show full label
        .text(yAxisLabel);
    }

  // Optional grid lines
  if (grid) {
    const yAxisGrid = d3.axisLeft(yScale)
      .tickSize(-dimensions.width)
      .tickFormat('')
      .ticks(5);

    const gridGroup = svg.append("g")
      .attr("class", "y-axis-grid")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(yAxisGrid)
      .style("color", "#D7D7D7")
      .style("stroke-dasharray", "2,2")
      .style("opacity", "0.3");
  }

  return { svg, yScale };
};


// Chart Renditions
// Bubble Chart
export const renderBubbleChart = (svg, vizdata, data, scales) => {
  // console.time("renderBubbleChart");

  const xScale = scales.xScale;
  const yScale = scales.yScale;

  const zScale = d3.scaleSqrt()
    .domain(d3.extent(data, d => d[vizdata.zaxis]))
    .range([2, 20]);

  const colorScale = d3.scaleOrdinal()
    .domain(data.map(d => d[vizdata.group_by[0]]))
    .range(customColors);

  // Append the bubbles
  svg.selectAll(".bubble")
    .data(data)
    .enter().append("circle")
    .attr("class", "bubble")
    .attr("cx", d => xScale(d[vizdata.xaxis]))
    .attr("cy", d => yScale(d[vizdata.yaxis]))
    .attr("r", d => zScale(d[vizdata.zaxis]))
    .style("fill", d => colorScale(d[vizdata.group_by[0]]))
    .attr("fill-opacity", "0.3")
    .attr("stroke", d => colorScale(d[vizdata.group_by[0]]))
    .style("stroke-width", "1.5")
    .attr("stroke-opacity", "1");
  // console.timeEnd("renderBubbleChart");

  return svg;
};

// Pie Chart
export const renderPieChart = (svg, dimensions, vizdata, data) => {

  // console.time("renderPieChart");

  // Set up radius
  const radius = Math.min(dimensions.width, dimensions.height) / 3;

  // Sum the values for each district (group by lp_districtNo and sum avg_oilProdVol_BBL)
  const categorySums = d3.rollup(
    data,
    v => d3.sum(v, d => d[vizdata.xaxis]),
    d => d[vizdata.group_by[0]]
  );

  // Calculate total sum
  const totalSum = d3.sum(categorySums.values());

  // Transform the rollup data to an array of objects
  const pieData = Array.from(categorySums, ([key, value]) => ({
    key, value, percentage: value / totalSum
  }));

  // Filter out small slices and combine them into "Other"
  const otherThreshold = 0.05;  // 5%
  let smallSlices = pieData.filter(d => d.percentage < otherThreshold);
  let largeSlices = pieData.filter(d => d.percentage >= otherThreshold);

  if (smallSlices.length > 0) {
    const otherValue = d3.sum(smallSlices, d => d.value);
    largeSlices.push({ key: 'Other', value: otherValue, percentage: otherValue / totalSum });
  }

  // Create a color scale
  const colorScale = d3.scaleOrdinal()
    .domain(largeSlices.map(d => d.key))
    .range(customColors);  // or use customColors if you have them defined

  // Create the pie generator
  const pie = d3.pie()
    .value(d => d.value)
    .sort(null);

  // Create the arc generator
  const arc = d3.arc()
    .innerRadius(0)
    .outerRadius(radius);

  // Create the outer group element
  const g = svg.append("g")
    .attr("transform", `translate(${dimensions.width / 2}, ${dimensions.height / 2})`);

  // Bind data to pie chart
  const arcs = g.selectAll(".arc")
    .data(pie(largeSlices))
    .enter().append("g")
    .attr("class", "arc");

  // Append the path (pie slices)
  arcs.append("path")
    .attr("d", arc)
    .attr("fill", d => colorScale(d.data.key))
    .attr("fill-opacity", "0.85")
    .attr("stroke", "black")
    .style("stroke-width", "1.3");

  // console.timeEnd("renderPieChart");

  return svg;
};

// Box Plot
export const renderBoxPlot = (svg, vizdata, data, scales) => {
  // console.time("renderBoxPlot");

  const xScale = scales.xScale;
  const yScale = scales.yScale;

  // Compute summary statistics for each category
  const summaryStats = d3.rollups(
    data,
    values => {
      const sortedValues = values.map(d => d[vizdata.yaxis]).sort(d3.ascending);
      const q1 = d3.quantile(sortedValues, 0.25);
      const median = d3.quantile(sortedValues, 0.5);
      const q3 = d3.quantile(sortedValues, 0.75);
      const interQuantileRange = q3 - q1;
      
      // Calculate whiskers
      const min = d3.min(sortedValues.filter(d => d >= q1 - 1.5 * interQuantileRange));
      const max = d3.max(sortedValues.filter(d => d <= q3 + 1.5 * interQuantileRange));

      return { q1, median, q3, interQuantileRange, min, max };
    },
    d => d[vizdata.xaxis]
  );

  // Draw the boxplots
  summaryStats.forEach(([key, stats], i) => {
    const boxWidth = xScale.bandwidth() * 0.25;  // Make the boxes thinner

    if(yScale(stats.q1) - yScale(stats.q3) > 0 ){
    // Draw the box
    svg.append("rect")
      .attr("x", xScale(key) + (xScale.bandwidth() - boxWidth) / 2)
      .attr("y", yScale(stats.q3))
      .attr("height", yScale(stats.q1) - yScale(stats.q3))
      .attr("width", boxWidth)
      .attr("stroke", customColors[i % customColors.length])
      .style("fill", customColors[i % customColors.length])
      .attr("fill-opacity", "0.6")
      .style("stroke-width", "2")
      .attr("stroke-opacity", "1");
    }

    // Draw the median line
    svg.append("line")
      .attr("x1", xScale(key) + (xScale.bandwidth() - boxWidth) / 2)
      .attr("x2", xScale(key) + (xScale.bandwidth() + boxWidth) / 2)
      .attr("y1", yScale(stats.median))
      .attr("y2", yScale(stats.median))
      .attr("stroke", customColors[i % customColors.length]);

    // Draw the whiskers
    // Whisker from min to q1
    svg.append("line")
      .attr("x1", xScale(key) + xScale.bandwidth() / 2)
      .attr("x2", xScale(key) + xScale.bandwidth() / 2)
      .attr("y1", yScale(stats.min))
      .attr("y2", yScale(stats.q1))
      .attr("stroke", customColors[i % customColors.length]);

    // Whisker from q3 to max
    svg.append("line")
      .attr("x1", xScale(key) + xScale.bandwidth() / 2)
      .attr("x2", xScale(key) + xScale.bandwidth() / 2)
      .attr("y1", yScale(stats.q3))
      .attr("y2", yScale(stats.max))
      .attr("stroke", customColors[i % customColors.length]);

    // Draw the min line
    svg.append("line")
      .attr("x1", xScale(key) + (xScale.bandwidth() - boxWidth) / 2)
      .attr("x2", xScale(key) + (xScale.bandwidth() + boxWidth) / 2)
      .attr("y1", yScale(stats.min))
      .attr("y2", yScale(stats.min))
      .attr("stroke", customColors[i % customColors.length]);

    // Draw the max line
    svg.append("line")
      .attr("x1", xScale(key) + (xScale.bandwidth() - boxWidth) / 2)
      .attr("x2", xScale(key) + (xScale.bandwidth() + boxWidth) / 2)
      .attr("y1", yScale(stats.max))
      .attr("y2", yScale(stats.max))
      .attr("stroke", customColors[i % customColors.length]);
  });

  // console.timeEnd("renderBoxPlot");
  return svg;
};

//Area Chart
export const renderAreaChart = (svg, dimensions, margin, vizdata, data, scales) => {
  // console.time("renderAreaChart");

  const xScale = scales.xScale;
  const yScale = scales.yScale;

  // Create the area generator
  const area = d3.area()
    .x(d => {
      const xValue = d[vizdata.xaxis];
      return xValue !== undefined && xValue !== null ? xScale(xValue) : NaN;
    })
    .y0(dimensions.height+margin.top)  // Fill the area down to the x-axis (origin)
    .y1(d => {
      const yValue = d[vizdata.yaxis];
      return yValue !== undefined && yValue !== null ? yScale(yValue) : NaN;
    });

  // Filter out invalid data points that might cause NaN issues
  const validData = data.filter(d => {
    const xValue = d[vizdata.xaxis];
    const yValue = d[vizdata.yaxis];
    return xValue !== undefined && xValue !== null && yValue !== undefined && yValue !== null;
  });

  // Append the area path to the SVG
  svg.append("path")
    .datum(validData)
    .attr("stroke", customColors[0])
    .style("fill", customColors[0])
    .attr("fill-opacity", "0.5")
    .style("stroke-width", "1.5")
    .attr("stroke-opacity", "1")
    .attr("d", area);

  // console.timeEnd("renderAreaChart");
  return svg;
};

export const createLegend = (svg, vizdata, data, dimensions, margin, leftIndent) => {
  // Legend configuration
  const legendSquareSize = 10; // Size of the color square
  const gapBetweenSquareAndLabel = 4; // Gap between the square and the label
  const gapBetweenItems = 12; // Gap between each legend item
  const maxLegendWidth = dimensions.width; // Max width for legend (width of the chart)

  // Extract categories based on vizdata.group_by[0]
  const categories = Array.from(new Set(data.map(d => d[vizdata.group_by[0]]))); 

  // Calculate available space for the legend
  const legendXStart = margin.left - leftIndent;
  const legendYStart = margin.top - 25; // Position legend above the chart (25px above)

  // Create a group for the legend
  const legendGroup = svg.append("g")
    .attr("class", "legend-group")
    .attr("transform", `translate(${legendXStart}, ${legendYStart})`);

  // Create a foreignObject for scrolling if legend is too wide
  const legendContainer = legendGroup.append("foreignObject")
    .attr("width", maxLegendWidth)
    .attr("height", 30) // Legend height (adjustable)
    .attr("x", -leftIndent) // Offset to avoid left cut-off
    .attr("y", 0)
    .style("overflow", "visible"); // Ensure it can extend beyond bounds

  const legendBody = legendContainer.append("xhtml:div")
    .style("display", "flex")
    .style("flex-direction", "row")
    .style("align-items", "center")
    .style("white-space", "nowrap") // Prevent items from wrapping
    .style("overflow-x", "auto") // Enable horizontal scrolling if needed
    .style("padding-left", "0px") // Avoid cutting off items on the left
    .style("padding-right", "20px") // Add some padding on the right side
    .style("width", "100%") // Take the full width of the container

    // Scrollbar styling for WebKit browsers (Chrome, Safari)
    .style("scrollbar-width", "thin") // For Firefox: make scrollbar thin
    .style("scrollbar-color", "transparent transparent") // Transparent scrollbar for Firefox
    .style("::-webkit-scrollbar", "height: 4px") // Thinner scrollbar for WebKit
    .style("::-webkit-scrollbar-thumb", "background-color: rgba(255, 255, 255, 0.1)") // Subtle thumb color to avoid hiding content
    .style("::-webkit-scrollbar-track", "background-color: transparent") // Transparent scrollbar track
    .style("::-webkit-scrollbar-thumb:hover", "background-color: rgba(255, 255, 255, 0.2)"); // Slightly visible on hover

  // Add legend items
  categories.forEach((category, i) => {
    const legendItem = legendBody.append("xhtml:div")
      .style("display", "flex")
      .style("align-items", "center")
      .style("margin-right", `${gapBetweenItems}px`);

    // Append the colored square
    legendItem.append("xhtml:div")
      .style("width", `${legendSquareSize}px`)
      .style("height", `${legendSquareSize}px`)
      .style("background-color", customColors[i % customColors.length]) // Use customColors
      .style("opacity", "0.8")
      .style("border-radius", "2px")
      .style("margin-right", `${gapBetweenSquareAndLabel}px`);

    // Append the label
    legendItem.append("xhtml:span")
      .style("font-size", "10px")
      .style("color", "#F9FAFB")
      .text(category);
  });

  return legendGroup;
};




