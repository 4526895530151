import React, { useState, useRef } from 'react';
import Modal from '../Modal/Modal';
import ExpandIcon from '../../../assets/darktheme/expand.svg'; // Import the image at the top

const formatClassName = (className) => {
  return className.split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const PanelHeader = ({ className, dataType, vizdata, setVizData }) => {
  const initialDisplayName = formatClassName(className);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(initialDisplayName);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const inputRef = useRef(null);

  const handleNameClick = () => {
    setIsEditing(true);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleNameBlur = () => {
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
    }
  };

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <div className="panel-header">
      <div className="panel-name">
        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            value={name}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            onKeyDown={handleKeyDown}
            className="panel-name-input"
          />
        ) : (
          <span onClick={handleNameClick}>{name}</span>
        )}
      </div>
      <div className="panel-icons">
        <button className="panel-icon-button" onClick={toggleModal}>
          <img src={ExpandIcon} alt="Expand" className="panel-icon" /> {/* Use the imported image */}
        </button>
      </div>

      {/* Render Modal */}
      {isModalOpen && (
        <Modal
          className={className}
          dataType={dataType}
          vizdata={vizdata}
          setVizData={setVizData}
          open={isModalOpen}
          handleClose={toggleModal}
        />
      )}
    </div>
  );
};

export default PanelHeader;