const panelGrid = {
  dashboard1: {
    panels: ['map', 'bubble-chart', 'pie-chart', 'line-chart', 'data-viewer', 'box-plot'],
    landingPanels: ['map', 'bubble-chart', 'pie-chart', 'line-chart', 'data-viewer', 'box-plot'],
    panelN: 6,
    default: {
      gridTracker: [10, 10, 10, 10, 10, 10, 10, 10, 10],
      panelPositions: [
        { rowStart: 1, colStart: 1, rowEnd: 6, colEnd: 7 },
        { rowStart: 6, colStart: 1, rowEnd: 9, colEnd: 4 },
        { rowStart: 9, colStart: 1, rowEnd: 11, colEnd: 4 },
        { rowStart: 6, colStart: 4, rowEnd: 11, colEnd: 7 },
        { rowStart: 1, colStart: 7, rowEnd: 6, colEnd: 10 },
        { rowStart: 6, colStart: 7, rowEnd: 11, colEnd: 10 },
      ],
      'map': '1 / 1 / 7 / 6',
      'bubble-chart': '1 / 6 / 4 / 9',
      'pie-chart': '1 / 9 / 4 / 11',
      'line-chart': '4 / 6 / 7 / 11',
      'data-viewer': '7 / 1 / 10 / 6',
      'box-plot': '7 / 6 / 10 / 11',
    },
    responsive: {
      gridTracker: [2, 2, 2, 2, 2],
      panelPositions: [
        { rowStart: 1, colStart: 1, rowEnd: 3, colEnd: 3 },
        { rowStart: 3, colStart: 1, rowEnd: 4, colEnd: 2 },
        { rowStart: 3, colStart: 2, rowEnd: 4, colEnd: 3 },
        { rowStart: 4, colStart: 1, rowEnd: 5, colEnd: 2 },
        { rowStart: 5, colStart: 1, rowEnd: 6, colEnd: 3 },
        { rowStart: 4, colStart: 2, rowEnd: 5, colEnd: 3 },
      ],
      'map': '1 / 1 / 3 / 3',
      'bubble-chart': '3 / 1 / 4 / 2',
      'pie-chart': '3 / 2 / 4 / 3',
      'line-chart': '4 / 1 / 5 / 2',
      'data-viewer': '5 / 1 / 6 / 3',
      'box-plot': '4 / 2 / 5 / 3',
    },
  },
  dashboard2: {
    panels: ['map', 'data-viewer'],
    landingPanels: ['map', 'data-viewer'],
    panelN: 2,
    default: {
      gridTracker: [10, 10, 10, 10, 10, 10, 10, 10, 10],
      panelPositions: [
        { rowStart: 1, colStart: 1, rowEnd: 6, colEnd: 6 },
        { rowStart: 6, colStart: 1, rowEnd: 11, colEnd: 6 },
      ],
      'map': '1 / 1 / 10 / 6',
      'data-viewer': '1 / 6 / 10 / 11',
    },
    responsive: {
      gridTracker: [2, 2, 2, 2, 2],
      panelPositions: [
        { rowStart: 1, colStart: 1, rowEnd: 3, colEnd: 3 },
        { rowStart: 3, colStart: 1, rowEnd: 5, colEnd: 3 },
      ],
      'map': '1 / 1 / 3 / 3',
      'data-viewer': '3 / 1 / 6 / 3',
    },
  },
  dashboard3: {
    panels: [],
    landingPanels: [],
    panelN: 0,
    default: {
      gridTracker: [],
      panelPositions: {},


    },
    responsive:{
      gridTracker: [],
      panelPositions: {},
      

    },
  },
  dashboard4: {
    panels: [],
    landingPanels: [],
    panelN: 0,
    default: {
      gridTracker: [],
      panelPositions: {},


    },
    responsive:{
      gridTracker: [],
      panelPositions: {},
      

    },
  },
  dashboard5: {
    panels: [],
    landingPanels: [],
    panelN: 0,
    default: {
      gridTracker: [],
      panelPositions: {},


    },
    responsive:{
      gridTracker: [],
      panelPositions: {},

      

    },
  },
  };
  
  export default panelGrid;
  