import React, { useRef, useState, useEffect} from 'react';
import Tabs from '../Tabs/Tabs';
import RenderPanels from '../Panel/RenderPanels';
import * as Helpers from './dashboardHelpers';
import defaultPanelValues from '../Panel/panelData/defaultValues';

const Dashboards = ({dataType, newPanel}) => {
  const viewportRef = useRef(null);
  const viewportParentRef = useRef(null);
  const [activeDashboard, setActiveDashboard] = useState('dashboard1');
  const [defaultValues, setDefaultValues] = useState(defaultPanelValues);

  // Update panel data whenever panel is added
  useEffect(() => {
    if (newPanel) {
      Helpers.updatePanelData(activeDashboard,
         newPanel,
         defaultValues,
         setDefaultValues);
      Helpers.updateGridPositions(activeDashboard,
        newPanel,
        defaultValues,
        setDefaultValues);
    }
  }, [newPanel]);

  return (
    <>
    <div className="tabs-parent">
      <Tabs setActiveDashboard={setActiveDashboard}/>
    </div>
    <div className="viewport-parent" ref={viewportParentRef}>
      <RenderPanels viewportRef={viewportRef} 
          viewportParentRef={viewportParentRef} 
          dataType={dataType} 
          defaultValues={defaultValues}
          dashboard={activeDashboard}/>
    </div>
  </>
  );
};

export default Dashboards;