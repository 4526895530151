import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useFetchTableData } from '../../api/fetchTableData';
import CircularProgress from '@mui/material/CircularProgress';
import { getDisplayName} from '../utils/utils';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Box from '@mui/material/Box';

const DataViewer = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isAuthenticated = useIsAuthenticated();
  const { fetchTableData } = useFetchTableData();

  useEffect(() => {
    if (isAuthenticated) {
      const getData = async () => {
        try {
          const data = await fetchTableData();
          setTableData(data); // Set the fetched data
          setLoading(false);  // Stop the loading spinner
        } catch (err) {
          setError(err.message);  // Set error message
          setLoading(false);
        }
      };

      getData(); // Fetch data on mount
    }
  }, [isAuthenticated]);

  // Define columns based on the keys of the first row of data
  const columns = tableData.length > 0
    ? Object.keys(tableData[0]).map((key) => ({
        field: key,
        headerName: getDisplayName(key),
        flex: 1,
        minWidth: Math.max(120, key.length),
      }))
    : [];

  // Display a loading spinner while data is being fetched
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Handle error state
  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div style={{ flexGrow: 1, display: 'flex', overflow:'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: 'transparent transparent',
        '&::WebkitScrollbar': {
          height: '4px',
        },
        '&::WebkitScrollbarThumb': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        '&::WebkitScrollbarTrack': {
          backgroundColor: 'transparent',
        },
        '&::WebkitScrollbarThumb:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
      }
      }}>
        <DataGrid
          rows={tableData.map((row, index) => ({ id: index, ...row }))}
          columns={columns}
          disableSelectionOnClick
          rowHeight={25}
          columnHeaderHeight={25}
          columnFooterHeight={25}
          checkboxSelection
          sx={{
            fontFamily: 'FoundryMonoline',
            // Make header and body text white, and background black
            '& .MuiDataGrid-root': {
                backgroundColor: 'black !important', // Table background color
                color: 'white', // Body text color
                border: 'none',
            },
            "& .MuiDataGrid-columnHeaders": {
                borderBottom: "none",
                borderLeft: "none",
                borderRight: "none",
                borderTop: "none",
                background: "black",
            },
            '& .MuiDataGrid-cell': {
              color: 'white', // Cell text color
              backgroundColor: 'black', // Cell background color
              borderBottom: "none",
                borderLeft: "none",
                borderRight: "none",
                borderTop: "none",
            },
            '.MuiDataGrid-columnSeparator': {
                display: 'none',
            },
            '&.MuiDataGrid-root': {
                border: 'none',
            },
            '& .MuiDataGrid-checkboxInput': {
                color: 'white',     
                opacity: 0.7,
            },
            '& .Mui-checked': {
                color: 'white !important',
            },
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)', // Darker scrollbar
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent', // Fully transparent track
            },
          }}
        />
      </div>
    </div>
  );
};

export default DataViewer;
