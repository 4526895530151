import panelSizes from '../Panel/panelData/panelSizes';

export const updatePanelData = (dashboard, newPanel, defaultValues, setDefaultValues) => {
    if(newPanel!==null){
        let addPanels = defaultValues.addPanels[dashboard];

        const updatedAddPanels = [...addPanels, newPanel];
        const updatedDefaultValues = { ...defaultValues };
        updatedDefaultValues.addPanels[dashboard] = updatedAddPanels;

        setDefaultValues(updatedDefaultValues);
    }
};

const calculatePosition = (newPanel, tracker, layout) => {
  if (newPanel != null) {
    const columns = (layout === 'default') ? 10 : 2;
    const panelWidth = panelSizes[layout][newPanel].width;
    const panelHeight = panelSizes[layout][newPanel].height;

    let position = { colStart: 0, colEnd: 0, rowStart: 0, rowEnd: 0 };
    let spaceAvailable = false;
    let rowStart = 0;
    let colStart = 0;

    if (tracker && tracker.length > 0) {
      // Check for available space in existing rows
      for (let i = 0; i < tracker.length; i++) {
        if (tracker[i] <= columns - panelWidth) {
          rowStart = i + 1;
          colStart = tracker[i] + 1;
          spaceAvailable = true;

          // Check if there is enough horizontal space in subsequent rows
          let spaceChecker = 0;
          for (let j = i + 1; j < i + panelHeight; j++) {
            if (tracker[j] > tracker[i] || j >= tracker.length) {
              spaceAvailable = false;
              break;
            } 
            else {
              spaceChecker++;
            }
          }
          if (spaceChecker === panelHeight - 1) {
            break;
          } else {
            spaceAvailable = false;
          }
        }
      }
    } else {
      // Initialize tracker if it's undefined or null
      tracker = [];
    }

    // If space is available, update the position
    if (spaceAvailable) {
      console.log("Space available to fit the panel in");
      position.rowStart = rowStart;
      position.colStart = colStart;
      position.rowEnd = rowStart + panelHeight;
      position.colEnd = colStart + panelWidth;

      // Create a copy of the tracker to avoid mutation
      const updatedTracker = [...tracker];
      for (let i = rowStart; i < rowStart + panelHeight; i++) {
        updatedTracker[i - 1] = updatedTracker[i - 1] + panelWidth;
      }
      return { position, updatedTracker };
    } else {
      console.log("Panel added to new row");

      // No space available, add the panel in a new row
      position.rowStart = tracker.length + 1;
      position.colStart = 1;
      position.rowEnd = position.rowStart + panelHeight;
      position.colEnd = position.colStart + panelWidth;

      // Create a copy of the tracker and add new rows
      const updatedTracker = [...tracker];
      for (let i = 0; i < panelHeight; i++) {
        updatedTracker.push(panelWidth); // Each new row starts with the width of the panel
      }
      // console.log("Updated tracker (new row):", updatedTracker);
      // console.log("Position:", position);
      return { position, updatedTracker };
    }
  }
};

export const updateGridPositions = (dashboard, newPanel, defaultValues, setDefaultValues) => {
  if(newPanel!==null){
    let layouts = ['default', 'responsive'];

    for (let i = 0; i < layouts.length; i++) {
      let layout = layouts[i];
      let gridTracker = defaultValues.gridTracker[dashboard][layout];
      let currentAddRows = defaultValues.addRows[dashboard][layout];

      const { position, updatedTracker } = calculatePosition(newPanel, gridTracker, layout);
      console.log(position, updatedTracker);

      if (position) {
        if (position.rowEnd - 1 > currentAddRows) {
          const updatedAddRows = { ...defaultValues };
          updatedAddRows.addRows[dashboard][layout] = position.rowEnd - 1;
          setDefaultValues(updatedAddRows);
        }
  
        const updatedDefaultTracker = { ...defaultValues };
        updatedDefaultTracker.gridTracker[dashboard][layout] = updatedTracker;
        setDefaultValues(updatedDefaultTracker);

        const updatedDefaultPosition = { ...defaultValues };
        updatedDefaultPosition.panelPositions[dashboard][layout].push(position);
        setDefaultValues(updatedDefaultPosition);
      }
    }
  }
};