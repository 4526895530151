import React from 'react';
import { Box } from '@mui/material';
import Visualization from '../../visualizations/Visualization';
import DataViewer from '../../data-viewer/DataViewer';

const MainPanel = ({ className, dataType, vizmodaldata }) => {
  return (
    <Box
      sx={{
        flexGrow: 1, // Main panel takes remaining space
        padding: 2,
        backgroundColor: 'black',
        overflow: 'hidden', // Prevent scrolling on the MainPanel itself
        height: '100%', // Make sure it fills the parent's height
        display: 'flex', // Ensure proper layout
      }}
    >
      {className.trim() !== 'data-viewer' && (
        <Visualization panelName={className} dataType={dataType} vizdata={vizmodaldata} />
      )}
      {className.trim() === 'data-viewer' && (
        <Box
          sx={{
            flexGrow: 1, // Occupies available space
            height: '100%', // Full height to ensure proper containment
            overflow: 'auto', // Enables both vertical and horizontal scrolling
            backgroundColor: 'black', // Matches modal background
          }}
        >
          <DataViewer className="data-viewer" />
        </Box>
      )}
    </Box>
  );
};

export default MainPanel;