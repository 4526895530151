export const VizSVG = ['box-plot', 'bubble-chart', 'pie-chart', 'line-chart'];
export const VizXaxis = ['bubble-chart', 'line-chart', 'box-plot'];
export const VizYaxis = ['bubble-chart', 'line-chart', 'box-plot'];
export const VizXGrid = ['bubble-chart', 'line-chart'];
export const VizYGrid = ['bubble-chart', 'line-chart', 'box-plot'];
export const Legend = ['bubble-chart', 'pie-chart'];

// Defining FITILA colors
// Fitila green, Fitila orange, Fitila yellow, Fitila blue, Fitila purple, Fitila grey
export const customColors = [
    '#009E73', // Green
    '#E69F00', // Yellow
    '#FF6601', // Orange
    '#56B4E9', // Blue
    '#786AAE', // Purple
    '#BCBCBC', // Grey
    '#DC7F3A', // Copper
    '#76B7B2', // Teal
    '#59A14F', // Olive Green
    '#F17CB0', // Warm Magenta
    '#B07AA1', // Mauve
    '#FF9DA7', // Coral Pink
    '#4E79A7', // Deep Blue
    '#9C755F', // Brown
    '#D37295'  // Warm Pink
  ];
  
  