import React, { useState } from 'react';
import './Menubar.css';
import homeIcon from '../../assets/darktheme/menubar/home.svg';
import aiIcon from '../../assets/darktheme/menubar/AI.svg';
import dataIcon from '../../assets/darktheme/menubar/data.svg';
import filterIcon from '../../assets/darktheme/menubar/filter.svg';
import exportIcon from '../../assets/darktheme/menubar/export.svg';
import shareIcon from '../../assets/darktheme/menubar/share.svg';
import saveIcon from '../../assets/darktheme/menubar/save.svg';
import panelIcon from '../../assets/darktheme/menubar/panel.svg';
import MenuDropdown from './utils/MenuDropdown';
import AiTab from '../AI/AiTab.js';
import Modal from '../structural/Modal/Modal';

const menuItems = [
  { name: 'Project', icon: homeIcon },
  { name: 'AI', icon: aiIcon },
  { name: 'Data', icon: dataIcon },
  { name: 'Filters', icon: filterIcon },
  { name: 'Export', icon: exportIcon },
  { name: 'Share', icon: shareIcon },
  { name: 'Save', icon: saveIcon },
  { name: 'Add Panel', icon: panelIcon },
];

const dropdownItems = ['map', 'bubble-chart', 'pie-chart', 'line-chart', 'data-viewer', 'box-plot'];

const Menubar = ({ setNewPanel }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isTabVisible, setIsTabVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control Modal visibility

  const toggleTabVisibility = () => {
    setIsTabVisible(prev => !prev);
  };

  const openModal = () => {
    setIsModalOpen(true); // Open the Modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the Modal
  };

  return (
    <div className="menubar">
      {menuItems.map((item, index) => (
        <div
          className={`menu-item ${item.name.toLowerCase().replace(/\s+/g, '-')}`}
          key={index}
          onClick={() => {
            if (item.name === 'AI') toggleTabVisibility(); // Toggle AI tab
            if (item.name === 'Data') openModal(); // Open Modal for Data button
          }}
          onMouseEnter={() => item.name === 'Add Panel' && setIsDropdownVisible(true)}
          onMouseLeave={() => item.name === 'Add Panel' && setIsDropdownVisible(false)}
        >
          <img src={item.icon} alt={`${item.name} icon`} className="menu-icon" />
          <span className="menu-label">{item.name}</span>
          {item.name === 'Add Panel' && isDropdownVisible && (
            <MenuDropdown items={dropdownItems} setNewPanel={setNewPanel} />
          )}
        </div>
      ))}
      {isTabVisible && <AiTab onClose={() => setIsTabVisible(false)} />}

      {/* Render Modal */}
      {isModalOpen && (
        <Modal
          className="data-viewer" // Pass className as data-viewer
          dataType={null}
          vizdata={null}
          setVizData={null}
          open={isModalOpen}
          handleClose={closeModal} // Handle close through state
        />
      )}
    </div>
  );
};

export default Menubar;