import { useMsal } from '@azure/msal-react';
import { protectedResources } from '../authConfig';

export const useApi = () => {
  const { instance, accounts } = useMsal();

  const callApi = async (endpoint, options = {}) => {
    const account = accounts[0];
    if (!account) {
      console.error("No account is available");
      throw new Error("No account is available");
    }

    // Find resource configuration by matching the full URL
    const resourceConfig = Object.values(protectedResources).find(resource => endpoint.startsWith(resource.endpoint));

    if (!resourceConfig) {
      console.error(`No configuration found for endpoint: ${endpoint}`);
      throw new Error(`No configuration found for endpoint: ${endpoint}`);
    }

    try {
      // Acquire the token and log it for debugging purposes
      const tokenResponse = await instance.acquireTokenSilent({
        scopes: resourceConfig.scopes,
        account,
      });

      const token = tokenResponse.accessToken;
      // console.log("Access Token:", token);  // Check if token is successfully acquired

      const headers = new Headers(options.headers || {});
      headers.append('Authorization', `Bearer ${token}`);

      const fetchOptions = {
        ...options,
        headers,
      };

      // Make the API call and log the response status
      // console.log("Making API call to:", endpoint);  // Debug the endpoint
      const apiResponse = await fetch(endpoint, fetchOptions);
      // console.log("API Response:", apiResponse);  // Log the response object

      if (!apiResponse.ok) {
        console.error("API call failed with status:", apiResponse.status, "and statusText:", apiResponse.statusText);
        throw new Error(`API call failed: ${apiResponse.statusText || apiResponse.status}`);
      }

      // Instead of processing the response here, return it to the caller
      return apiResponse;
    } catch (error) {
      console.error("Token acquisition or API call failed", error);

      if (error.name === "InteractionRequiredAuthError") {
        console.warn("Interaction required, redirecting to login...");
        instance.acquireTokenRedirect({
          scopes: resourceConfig.scopes,
          account,
        });
      } else {
        throw error;  // Re-throw other errors
      }
    }
  };

  return { callApi };
};