import React, { useState, useEffect } from 'react';
import Panel from './Panel';
import landingpaneldata from './panelData/landingPanelData';
import panelGrid from './panelData/panelGrid';

const RenderPanels = ({ viewportRef, viewportParentRef, dataType, defaultValues, dashboard }) => {
  const [rowHeight, setRowHeight] = useState();
  const [rows, setRows] = useState(9);
  const [columns, setColumns] = useState(10);
  const margin = 7;
  const gap = 7;
  const [isResponsive, setIsResponsive] = useState(
    window.innerWidth <= 1080 || window.innerWidth / window.innerHeight <= 7 / 5
  );
  const landingPanels = panelGrid[`${dashboard}`].landingPanels;

  useEffect(() => {
    setRows(isResponsive ? 5 : 9);
    setColumns(isResponsive ? 2 : 10);
  }, [isResponsive]);

  // Effect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(
        window.innerWidth <= 1080 || window.innerWidth / window.innerHeight <= 7 / 5
      );
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // Cleanup event listener on unmount
  }, []);

  const calculateRowHeight = () => {
    if (viewportParentRef.current) {
      const viewportHeight = viewportParentRef.current.clientHeight;
      if(isResponsive) {
        const rowHeight = viewportHeight * 0.25;
        setRowHeight(rowHeight);
      }
      else {
        const rowHeight = (viewportHeight - (10 * (rows - 1)))/rows;
        setRowHeight(rowHeight);
      }
    }
  };

  useEffect(() => {
    calculateRowHeight();
    window.addEventListener('resize', calculateRowHeight);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', calculateRowHeight);
    };
  }, [isResponsive, viewportParentRef.current, rows]);

  const viewportStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gridTemplateRows: `repeat(${rows}, ${rowHeight}px)`,
    gridColumnGap: `${gap}px`,
    gridRowGap: `${gap}px`,
    margin: `${margin}px`,
  };

  const addRowsVal = defaultValues.addRows[`${dashboard}`][isResponsive ? 'responsive' : 'default'];
  const panelPositionsVal = defaultValues.panelPositions[`${dashboard}`][isResponsive ? 'responsive' : 'default'];
  const panels = defaultValues.addPanels[`${dashboard}`];

    const addViewportStyle = {
      display: 'grid',
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      gridTemplateRows: `repeat(${addRowsVal}, ${rowHeight}px)`,
      gridColumnGap: `${gap}px`,
      gridRowGap: `${gap}px`,
      margin: `${margin}px`,
    };

  return (
    <>
    {(dashboard === 'dashboard1' || dashboard === 'dashboard2') && (
    <div className="viewport" ref={viewportRef} style={viewportStyle}>
        {landingPanels.map((panel, index) => (
          <Panel
            key={`${dashboard}`+index}
            className={panel}
            dataType={dataType}
            landingpaneldata={landingpaneldata[panel]}
            style={{ gridArea: isResponsive ? panelGrid[`${dashboard}`].responsive[panel] : panelGrid[`${dashboard}`].default[panel] }}
          />
        )
        )}
    </div>
    )}
    {addRowsVal > 0 && panelPositionsVal && panelPositionsVal.length === panels.length && (
    <div className="addViewport" style={addViewportStyle}>
        {panels && panels.length > 0 && panels.map((panel, index) => (
          <Panel
            key={`new-${dashboard}-${index}`}
            className={panel}
            dataType={dataType}
            landingpaneldata={landingpaneldata[panel]}
            style={{ gridArea: panelPositionsVal[index].rowStart + ' / ' + panelPositionsVal[index].colStart + ' / ' + panelPositionsVal[index].rowEnd + ' / ' + panelPositionsVal[index].colEnd }}
          />
      ))}
    </div>
    )}
    </>
  );
};

export default RenderPanels;