import React, {useState} from 'react';
import './Panel.css';
import PanelHeader from './PanelHeader';
import Visualization from '../../visualizations/Visualization';
import MapWithLayers from '../../map/MapWithLayers';
import DataViewer from '../../data-viewer/DataViewer';
import {VizSVG} from '../../visualizations/visualization-helpers/visualizationFlags';
import "leaflet/dist/leaflet.css";

const Panel = React.forwardRef(({ className, style, dataType, landingpaneldata}, ref) => {

  const [vizdata, setVizData] = useState( landingpaneldata);

  return (
    <div ref={ref} className={`panel ${className || ''}`} style={style}>
      <PanelHeader className={className} dataType={dataType} vizdata={vizdata} setVizData = {setVizData}/>
      {VizSVG.includes(className.trim()) && (
      <Visualization panelName={className} dataType={dataType} vizdata={vizdata}/> 
      )}
      {className.trim() === 'map' && (
        <MapWithLayers className="map" />
      )}
      {className.trim() === 'data-viewer' && (
        <DataViewer className="data-viewer" />
      )}
    </div>
  );
});

export default Panel;