// MenuDropdown.js
import React from 'react';

const MenuDropdown = ({ items, setNewPanel }) => {

  const handleItemClick = (item) => {
    setNewPanel(item);
  };
  

  return (
    <div className="dropdown">
      {items.map((item, index) => (
        <div key={index} className="dropdown-item"
        onClick={() => handleItemClick(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default MenuDropdown;