const landingpaneldata = {
    'bubble-chart': {
        xaxis: 'avg_oilProdVol_BBL',
        xAggregation: 'approximate_median',
        yaxis: 'avg_gasProdVol_MCF',
        yAggregation: 'approximate_median',
        zaxis: 'avg_csgdProdVol_MCF',
        zAggregation: 'approximate_median',
        group_by: ['lp_districtNo'],
    },

    'pie-chart': {
        xaxis: 'avg_oilProdVol_BBL',
        xAggregation: 'mean',
        yaxis: null,
        yAggregation: null,
        zaxis: null,
        zAggregation: null,
        group_by: ['lp_districtNo'],
    },

    'line-chart': {
        xaxis: 'lp_cycleY',
        xAggregation: null,
        yaxis: 'avg_oilProdVol_BBL',
        yAggregation: 'approximate_median',
        zaxis: null,
        zAggregation: null,
        group_by: ['lp_cycleY'],
    },

    'box-plot': {
        xaxis: 'lp_districtNo',
        xAggregation: null,
        yaxis: 'avg_oilProdVol_BBL',
        yAggregation: 'mean',
        zaxis: null,
        zAggregation: null,
        group_by: ['lp_districtNo', 'lp_cycleY'],
    },
};

export default landingpaneldata;