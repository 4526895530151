import React, { useState, useEffect } from 'react';
import './App.css';
import './login.css';
import Dashboards from './components/structural/Dashboards/Dashboards.js';
import NavigationBar from './components/navigation/NavigationBar';
import { useFetchDataType } from './api/fetchDataType.js';
import { SignInButton } from './components/auth/AuthButton';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser'; 

function App() {
  const [dataType, setDataType] = useState(null);
  const [newPanel, setNewPanel] = useState(null);
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();
  const { fetchDataType } = useFetchDataType();

  useEffect(() => {
    if (isAuthenticated && accounts.length > 0) { 
      const loadDataType = async () => {
        try {
          const result = await fetchDataType();  
          if (result) {
            setDataType(result);  
          }
        } catch (error) {
          console.error("Error fetching data type:", error); 
        }
      };
      loadDataType();
    }
  }, [isAuthenticated, accounts]); 

  const isLoading = inProgress !== InteractionStatus.None;

  if (isLoading) {
    return (
      <div className="loading-screen">
        <h2>Loading...</h2>
      </div>
    );
  }

  return (
    <div className="app">
      {isAuthenticated ? (
        <>
          <div className="menu">
            <NavigationBar setNewPanel={setNewPanel} authentication={isAuthenticated} />
          </div>
          <div className="workspace">
            <Dashboards 
              dataType={dataType}
              newPanel={newPanel}
            />
          </div>
        </>
      ) : (
        <div className="login-background">
          <div className="login-background-image" />
          <div className="login-content">
            <h1>ODIS: Oilfield Data Intelligence and Simulation</h1>
            <p>We're building a one-stop-shop solution that brings together diverse datasets, AI models, and advanced simulation capabilities into a single cloud-based platform.</p>
            <SignInButton />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
