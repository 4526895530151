// MapWithLayers.js
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./MapWithLayers.css";
import { customColors } from "../visualizations/visualization-helpers/visualizationFlags";
import { useApi } from "../../api/ApiClient";
import MarkerClusterGroup from "react-leaflet-cluster";
import LayerLegend from "./LayerLegend";
import { getLayerColorMapping, renameLayer } from "./LayerUtils";
import ClusterZoomHandler from "./ClusterZoomHandler"; 

const BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

const defaultCategorizedLayers = {
  "Texas Oil Gas Wells": {
    column: "WELL_TYPE",
    geometry: "point",
  },
  "Texas Shale Plays": {
    column: "SHALE_PLAY",
    geometry: "polygon",
  },
  "Texas Shale Basins": {
    column: "NAME",
    geometry: "polygon",
  },
  // Add more layers as needed
};

const defaultUnselectedLayers = ["Texas Shale Basins", "United States Boundaries"];

const defaultPolygonStyle = {
  color: "white",
  weight: 1,
  opacity: 0.1,
  fillOpacity: 0.05,
};

const MapWithLayers = ({
  categorizedLayers = defaultCategorizedLayers,
  unselectedLayers = defaultUnselectedLayers,
}) => {
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [isLegendOpen, setIsLegendOpen] = useState(true);
  const [selectedLayers, setSelectedLayers] = useState({});
  const [dimensions, setDimensions] = useState({ width: null, height: null });
  const divRef = useRef(null);
  const [layerNames, setLayerNames] = useState([]);
  const { callApi } = useApi();

  const memoizedCustomColors = useMemo(() => customColors, []);

  // Fetch layer names once
  useEffect(() => {
    const fetchLayerNames = async () => {
      try {
        const response = await callApi(`${BASE_URL}/api/columns/map`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        const data = await response.json();
        setLayerNames(data.map_names);
      } catch (error) {
        console.error("Error fetching layer names:", error);
      }
    };
    fetchLayerNames();
  }, []);

  // Fetch GeoJSON data for each layer
  useEffect(() => {
    const fetchGeoJsonData = async () => {
      try {
        const dataPromises = layerNames.map(async (layerName) => {
          const response = await callApi(
            `${BASE_URL}/api/data/map?layer_name=${layerName}`,
            { method: "GET", headers: { "Content-Type": "application/json" } }
          );
          if (!response.ok) {
            throw new Error(`Failed to fetch ${layerName}: ${response.statusText}`);
          }
          const data = await response.json();
          return { name: renameLayer(layerName), data };
        });

        const layers = await Promise.all(dataPromises);
        setGeoJsonData(layers);

        // Initialize selectedLayers
        const initialSelectedState = {};
        layers.forEach((layer) => {
          initialSelectedState[layer.name] = !unselectedLayers.includes(layer.name);
        });
        console.log("Initializing selectedLayers:", initialSelectedState);
        setSelectedLayers(initialSelectedState);
      } catch (error) {
        console.error("Error fetching GeoJSON data:", error);
      }
    };

    if (layerNames.length > 0) {
      fetchGeoJsonData();
    }
  }, [layerNames, unselectedLayers]);

  const layerColorMappings = useMemo(() => {
    return getLayerColorMapping(geoJsonData, categorizedLayers, memoizedCustomColors);
  }, [geoJsonData, categorizedLayers, memoizedCustomColors]);

  // Handle resize
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setDimensions({ width, height });
      }
    });

    if (divRef.current) resizeObserver.observe(divRef.current);

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  const handleLayerChange = useCallback(
    (layerName) => {
      setSelectedLayers((prevSelectedLayers) => ({
        ...prevSelectedLayers,
        [layerName]: !prevSelectedLayers[layerName],
      }));
    },
    [setSelectedLayers]
  );

  const getLayerGeometry = useCallback(
    (layerName) => {
      const layerConfig = categorizedLayers[layerName];
      return layerConfig && layerConfig.geometry ? layerConfig.geometry : "polygon";
    },
    [categorizedLayers]
  );

  const pointToLayer = useCallback(
    (feature, latlng, layerName) => {
      const layerConfig = categorizedLayers[layerName];
      const color =
        layerConfig &&
        layerConfig.column &&
        feature.properties[layerConfig.column]
          ? layerColorMappings[layerName]?.[feature.properties[layerConfig.column]] || "green"
          : "green";
      return L.circleMarker(latlng, {
        radius: 3,
        fillColor: color,
        color: color,
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8,
      });
    },
    [categorizedLayers, layerColorMappings]
  );

  const stylePolygon = useCallback(
    (feature, layerName) => {
      const layerConfig = categorizedLayers[layerName];
      const color =
        layerConfig &&
        layerConfig.column &&
        feature.properties[layerConfig.column]
          ? layerColorMappings[layerName]?.[feature.properties[layerConfig.column]] || "green"
          : "white";
      return {
        color: color,
        weight: 1,
        fillOpacity: layerConfig ? 0.2 : 0.05,
      };
    },
    [categorizedLayers, layerColorMappings]
  );

  // Use this function to bind tooltips imperatively
  const onEachFeature = useCallback((feature, layer) => {
    if (feature.properties) {
      // Retrieve layer name from the outer scope or feature properties
      // Adjust this as per your data structure
      const layerName = layer.options.pane === 'boundariesPane' ? "United States Boundaries" : "Data Layer";

      const tooltipContent = Object.entries(feature.properties)
        .map(([key, value]) => `<strong>${key}:</strong> ${value}`)
        .join("<br>");

      layer.bindTooltip(tooltipContent, { className: "custom-tooltip", sticky: true });
    }
  }, []);

  // Define the custom cluster icon function
  const createClusterCustomIcon = (cluster) => {
    const count = cluster.getChildCount();

    // Customize the cluster icon based on the count
    let size = "small";
    if (count >= 100) {
      size = "large";
    } else if (count >= 50) {
      size = "medium";
    }

    // Define className based on size
    const className = `marker-cluster marker-cluster-${size}`;

    // Create HTML for the cluster icon with a title for the tooltip
    const html = `
      <div title="Cluster size: ${count} wells">
        <span>${count}</span>
      </div>
    `;

    return L.divIcon({
      html: html,
      className: className,
      iconSize: L.point(40, 40, true), // Adjust size as needed
    });
  };

  return (
    <div className="map-container" ref={divRef}>
      {dimensions.width && dimensions.height && (
        <MapContainer
          center={[31.82882784132778, -99.39119024724208]}
          zoom={6.0}
          style={{ height: dimensions.height, width: dimensions.width }}
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          />
          {selectedLayers &&
            geoJsonData.map((layer) => {
              const geometry = getLayerGeometry(layer.name);
              if (!selectedLayers[layer.name]) return null;

              if (categorizedLayers[layer.name]) {
                if (geometry === "point") {
                  return (
                    <MarkerClusterGroup 
                      key={layer.name} 
                      chunkedLoading 
                      spiderfyOnMaxZoom={false}
                      disableClusteringAtZoom={7}
                      iconCreateFunction={createClusterCustomIcon} // Add custom icon function
                    >
                      <GeoJSON
                        data={layer.data}
                        pointToLayer={(feature, latlng) => pointToLayer(feature, latlng, layer.name)}
                        onEachFeature={onEachFeature}
                      />
                    </MarkerClusterGroup>
                  );
                } else if (geometry === "polygon") {
                  return (
                    <GeoJSON
                      key={layer.name}
                      data={layer.data}
                      style={(feature) => stylePolygon(feature, layer.name)}
                      onEachFeature={onEachFeature}
                    />
                  );
                }
              } else {
                // Default styling for layers not in categorizedLayers
                return (
                  <GeoJSON
                    key={layer.name}
                    data={layer.data}
                    style={defaultPolygonStyle}
                    onEachFeature={onEachFeature}
                  />
                );
              }
            })}
        </MapContainer>
      )}
      <button className="toggle-legend" onClick={() => setIsLegendOpen(!isLegendOpen)}>
        {isLegendOpen ? "Hide Legend" : "Show Legend"}
      </button>
      {isLegendOpen && selectedLayers && (
        <LayerLegend
          geoJsonData={geoJsonData}
          selectedLayers={selectedLayers}
          handleLayerChange={handleLayerChange}
          layerColorMappings={layerColorMappings}
          categorizedLayers={categorizedLayers}
        />
      )}
    </div>
  );
};

export default MapWithLayers;
