import React from 'react';
import './NavigationBar.css';
import Menubar from './Menubar';
// import SearchBar from './SearchBar';
import Toolbar from './Toolbar';
import collapsedMenu from '../../assets/darktheme/toolbar/collapsed-menu.svg';

const NavigationBar = ( {setNewPanel, authentication} ) => {
  return (
    <div className="navigation-bar">
      <Menubar className="menubar" setNewPanel={setNewPanel}/>
      {/* <SearchBar className="searchbar"/> */}
      <Toolbar className="toolbar" authentication={authentication}/>
      <img src={collapsedMenu} alt="menu" className="collapsed-menu-icon" />
    </div>
  );
};

export default NavigationBar;