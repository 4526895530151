const defaultValues = {
    addRows: {
        dashboard1: {
            default: 0,
            responsive: 0
        },
        dashboard2: {
            default: 0,
            responsive: 0
        },
        dashboard3: {
            default: 0,
            responsive: 0
        },
        dashboard4: {
            default: 0,
            responsive: 0
        },
        dashboard5: {
            default: 0,
            responsive: 0
        }
    },
    gridTracker: {
        dashboard1: {
            default: [],
            responsive: []
        },
        dashboard2: {
            default: [],
            responsive: []
        },
        dashboard3: {
            default: [],
            responsive: []
        },
        dashboard4: {
            default: [],
            responsive: []
        },
        dashboard5: {
            default: [],
            responsive: []
        }
    },
    addPanels: {
        dashboard1: [],
        dashboard2: [],
        dashboard3: [],
        dashboard4: [],
        dashboard5: []
    },
    panelPositions: {
        dashboard1: {
            default: [],
            responsive: []
        },
        dashboard2: {
            default: [],
            responsive: []
        },
        dashboard3: {
            default: [],
            responsive: []
        },
        dashboard4: {
            default: [],
            responsive: []
        },
        dashboard5: {
            default: [],
            responsive: []
        }
    }
};
    
export default defaultValues;
    