import React from 'react';
import { Dialog, DialogContent, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MapWithLayers from '../../map/MapWithLayers';
import VizSidePanel from './VizSidePanel';
import DataSidePanel from './DataSidePanel';
import MainPanel from './MainPanel';
import './Modal.css';

const formatClassName = (className) => {
  return className.split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const Modal = ({ className, dataType, vizdata, setVizData, open, handleClose }) => {
  const initialDisplayName = formatClassName(className);
  const [vizmodaldata, setVizmodaldata] = React.useState({ ...vizdata });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={false}
      fullWidth
      PaperProps={{
        sx: {
          width: '75vw',
          height: '80vh',
          backgroundColor: 'black',
          color: 'white',
          backdropFilter: 'blur(50px)',
          boxShadow: '0px 4px 20px rgba(255, 255, 255, 0.3)',
          borderRadius: '16px',
          overflow: 'hidden',
        },
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: 0,
          overflow: 'hidden',
        }}
      >
        {/* Header */}
        <Box
          className={className}
          sx={{
            height: '25px',
            backgroundColor: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 2,
          }}
        >
          {initialDisplayName}

          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Body */}
        {className.trim() !== 'map' && (
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              backgroundColor: 'black',
              overflowY: 'auto',
            }}
          >
            {/* Conditionally render the correct side panel */}
            {className.trim() === 'data-viewer' ? (
              <DataSidePanel />
            ) : (
              <VizSidePanel
                className={className}
                dataType={dataType}
                vizmodaldata={vizmodaldata}
                setVizmodaldata={setVizmodaldata}
                setVizData={setVizData}
              />
            )}
            <MainPanel className={className} dataType={dataType} vizmodaldata={vizmodaldata} />
          </Box>
        )}

        {className.trim() === 'map' && (
          <MapWithLayers className="map" />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;