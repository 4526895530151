// Tabs.js
import React, { useState } from 'react';
import './Tabs.css';

const Tabs = ({ setActiveDashboard }) => {
  const [dashboards, setDashboards] = useState(['dashboard1', 'dashboard2']);
  const [activeTab, setActiveTab] = useState('dashboard1');
  
  // Function to add new dashboard
  const addDashboard = () => {
    if (dashboards.length < 5) {
      setDashboards([...dashboards, `dashboard${dashboards.length + 1}`]);
    }
  };

  const handleTabClick = (dashboard) => {
    setActiveTab(dashboard);  // Set the active tab
    setActiveDashboard(dashboard);  // Call the function to set the active dashboard
  };

  return (
    <div className="tabs">
      {dashboards.map((dashboard, index) => (
        <button 
          key={index} 
          className={`tab-button ${activeTab === dashboard ? 'active' : ''}`}
          onClick={() => handleTabClick(dashboard)}
        >
          {dashboard.charAt(0).toUpperCase() + dashboard.slice(1)}
        </button>
      ))}
      {dashboards.length < 5 && (
        <button className="tab-button plus-button" onClick={addDashboard}>
          +
        </button>
      )}
    </div>
  );
};

export default Tabs;
