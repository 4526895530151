// LayerUtils.js

export const renameLayer = (layerName) =>
    layerName
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  
  export const getLayerColorMapping = (layers, categorizedLayers, colors) => {
    const colorMapping = {};
  
    layers.forEach((layer) => {
      const layerName = layer.name;
      const layerConfig = categorizedLayers[layerName];
      if (layerConfig && layerConfig.column) {
        const { column } = layerConfig;
        const uniqueValues = new Set();
        layer.data.features.forEach((feature) => {
          uniqueValues.add(feature.properties[column]);
        });
        colorMapping[layerName] = {};
        Array.from(uniqueValues).forEach((value, index) => {
          colorMapping[layerName][value] = colors[index % colors.length];
        });
      }
    });
  
    return colorMapping;
  };

