import * as arrow from "apache-arrow";
import * as aq from 'arquero';
import { useApi } from './ApiClient';
const BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

export const useFetchTableData = () => {
  const { callApi } = useApi();  // Use callApi from useApi

  const fetchTableData = async () => {
    try {
      const response = await callApi(`${BASE_URL}/api/data/table`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      // Get the binary response as an ArrayBuffer
      const arrayBuffer = await response.arrayBuffer();


      // Convert the ArrayBuffer to an Apache Arrow Table
      const arrowTable = arrow.tableFromIPC(arrayBuffer);
      
      // Convert the Arrow Table to an Arquero table
      const tableData = aq.fromArrow(arrowTable);

      // Convert the Arquero table to an array of objects for use in the app
      return tableData.objects();

    } catch (error) {
      console.error('Error fetching table data:', error);
      throw error;  // Re-throw the error for higher-level handling
    }
  };

  return { fetchTableData };
};