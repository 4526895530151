import React, { useEffect, useRef, useState } from 'react';
import './Visualization.css';
import * as VizFlags from './visualization-helpers/visualizationFlags';
import * as Viz from './visualization-helpers/vizHelperFunctions';
// import { fetchAggregatedData } from '../../api/fetchData';
import { useFetchAggregatedData } from '../../api/fetchAggregatedData';
import * as d3 from 'd3';
// import datamock from './visualization-helpers/data';
import { transformData } from '../utils/utils';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

const Visualization = ({ panelName, dataType, vizdata }) => {
  const svgRef = useRef(null);
  const divRef = useRef();
  const [dimensions, setDimensions] = useState({ width: null, height: null });
  const margin = { top: 30, right: 20, bottom: 55, left: 60 };
  const [data, setData] = useState(null);
  const { fetchAggregatedData } = useFetchAggregatedData();  
  const isAuthenticated = useIsAuthenticated();
  
  // Fetch data for the Visualization
  useEffect(() => {
  const loadAggregatedData = async () => {
    if (isAuthenticated && vizdata && VizFlags.VizSVG.includes(panelName.trim())) {
      try {
        const transformedData = transformData(vizdata);  // Ensure data transformation is done properly
        const agg_data = await fetchAggregatedData(transformedData.apidata);
        if (agg_data) {
          setData(agg_data);  // Set the aggregated data in the component state
        }
      } catch (error) {
        console.error("Error fetching aggregated data:", error);  // Log error for debugging
      }
    }
  };

    loadAggregatedData();  // Fetch data when dependencies change
  }, [vizdata, panelName, isAuthenticated]);

  // Handle resizing of the SVG
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setDimensions({ width, height });
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  // Create Visualization
  useEffect(() => {
    if (data && dataType && dataType.column_types && dimensions) {
      if (VizFlags.VizSVG.includes(panelName.trim())) {
        var innerWidth = dimensions.width - margin.left - margin.right;
        var innerHeight = dimensions.height - margin.top - margin.bottom;
        var innerDimensions = { width: innerWidth, height: innerHeight };
        let scales = {xScale: null, yScale: null, zScale: null};

        // Clear existing SVG content before creating new SVG elements
        d3.select(svgRef.current).selectAll('*').remove();

        var svg = Viz.createSVG(svgRef, dimensions, margin);

        if(VizFlags.VizXaxis.includes(panelName.trim())) {
          const xReturn = Viz.createXAxis(
            svg,
            data, 
            vizdata.xaxis, 
            dataType.column_types[vizdata.xaxis], 
            innerDimensions, 
            margin,
            VizFlags.VizXGrid.includes(panelName.trim())
          );
          svg = xReturn.svg;
          scales.xScale = xReturn.xScale;
        }

        if(VizFlags.VizYaxis.includes(panelName.trim())) {
          const yReturn = Viz.createYAxis(
            svg,
            data, 
            vizdata.yaxis, 
            dataType.column_types[vizdata.yaxis], 
            innerDimensions, 
            margin,
            VizFlags.VizYGrid.includes(panelName.trim())
          );
          svg = yReturn.svg;
          scales.yScale = yReturn.yScale;
        }
        
        switch(panelName.trim()) {
          case 'bubble-chart':
            Viz.renderBubbleChart(svg,
              vizdata,
              data,
              scales
            );
            break;
          
          case 'pie-chart':
            Viz.renderPieChart(svg,
              dimensions,
              vizdata,
              data
            );
            break;
            
          case 'box-plot':
            Viz.renderBoxPlot(svg,
              vizdata,
              data,
              scales
            );
            break;

          case 'line-chart':
            Viz.renderAreaChart(svg,
              innerDimensions,
              margin,
              vizdata,
              data,
              scales
            );
            break;
          
          default:
            break;
        }
      
        if(VizFlags.Legend.includes(panelName.trim())){
          Viz.createLegend(svg,
            vizdata,
            data,
            innerDimensions,
            margin,
            VizFlags.VizYaxis.includes(panelName.trim())? 0: 20
          );
        }
      }
    }
  }, [data, dimensions, panelName, dataType, vizdata]);

  return (
    <div className={`visualization visualization-${panelName}`} ref={divRef}>
      <svg className="visualizations-svg" ref={svgRef}></svg>
    </div>
  );
};

export default Visualization;