// Mapping of column names to display names
const columnDisplayNames = {
  "lp_leaseNo": "Lease Number",
  "lp_operatorNo": "Operator No.",
  "lp_fieldNo": "Field No.",
  "lp_leaseName": "Lease Name",
  "lp_operatorName": "Operator Name",
  "lp_cycleY": "Production Cycle Year",
  "lp_oilGasCode": "Oil or Gas Production Code",
  "lp_districtNo": "District No.",
  "avg_oilProdVol_BBL": "Average Oil Production Volume (BBL)",
  "avg_oilAllow_BBL": "Average Oil Allowance (BBL)",
  "avg_oilEndingBal_BBL": "Average Oil Ending Balance (BBL)",
  "avg_gasProdVol_MCF": "Average Gas Production Volume (MCF)",
  "avg_gasAllow_MCF": "Average Gas Allowance (MCF)",
  "avg_gasLiftInjVol_MCF": "Average Gas Lift Injection Volume (MCF)",
  "avg_condProdVol_BBL": "Average Condensate Production Volume (BBL)",
  "avg_condLimit_BBL": "Average Condensate Limit (BBL)",
  "avg_condEndingBal_BBL": "Average Condensate Ending Balance (BBL)",
  "avg_csgdProdVol_MCF": "Average Casinghead Gas Production Volume (MCF)",
  "avg_csgdLimit_MCF": "Average Casinghead Gas Limit (MCF)",
  "avg_csgdGasLift_MCF": "Average Casinghead Gas Lift (MCF)",
  "avg_oilTotDisp_BBL": "Average Oil Total Disposition (BBL)",
  "avg_gasTotDisp_MCF": "Average Gas Total Disposition (MCF)",
  "avg_condTotDisp_BBL": "Average Condensate Total Disposition (BBL)",
  "avg_csgdTotDisp_MCF": "Average Casinghead Gas Total Disposition (MCF)",
  "mean": "Mean",
  "approximate_median": "Median",
};

// Function to get the display name for a given column
export const getDisplayName = (columnName) => {
  return columnDisplayNames[columnName] || columnName;  // Return the display name or the original column name if not found
};

// Function to get the column name for a given display name
export const getColumnName = (displayName) => {
  // Create a reverse mapping from display names to column names
  const reverseMapping = Object.entries(columnDisplayNames).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});

  // Return the column name or undefined if not found
  return reverseMapping[displayName];
};

export const transformData = (input) => {
  const aggregate_columns = {};
  
  // Check if xaxis and xAggregation are provided, if so, add to aggregate_columns
  if (input.xaxis && input.xAggregation) {
    aggregate_columns[input.xaxis] = input.xAggregation;
  }

  // Check if yaxis and yAggregation are provided, if so, add to aggregate_columns
  if (input.yaxis && input.yAggregation) {
    aggregate_columns[input.yaxis] = input.yAggregation;
  }

  // Check if zaxis and zAggregation are provided, if so, add to aggregate_columns
  if (input.zaxis && input.zAggregation) {
    aggregate_columns[input.zaxis] = input.zAggregation;
  }

  // Construct the final output
  const result = {
    aggregate_columns: aggregate_columns,
    group_by: input.group_by || []
  };

  return { apidata: result };
};