import React, { useState } from 'react';
import './AiTab.css';
import micIcon from '../../assets/darktheme/ai/mic.svg';
import sendIcon from '../../assets/darktheme/ai/send.svg';

const AiTab = ({ onClose }) => {
  const [height, setHeight] = useState(15); // Initial height to 15%
  const [isResizing, setIsResizing] = useState(false);

  const startResizing = (e) => {
    e.preventDefault();
    setIsResizing(true);
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      const newHeight = ((window.innerHeight - e.clientY) / window.innerHeight) * 100;
      if (newHeight >= 10 && newHeight <= 80) {
        setHeight(newHeight);
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  return (
    <div className="ai-tab" style={{ height: `${height}vh` }}>
      <div className="resize-handle" onMouseDown={startResizing} />
      <div className="tab-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        
        <div className="ai-controls">
          <img src={micIcon} alt="Microphone" className="icon mic-icon" />
          <p className="text">Collaborate with AI Agent</p>
          <img src={sendIcon} alt="Send" className="icon send-icon" />
        </div>
      </div>
    </div>
  );
};

export default AiTab;