// LayerLegend.js
import React from "react";

const LayerLegend = ({
  geoJsonData,
  selectedLayers,
  handleLayerChange,
  layerColorMappings,
  categorizedLayers,
}) => (
  <div className="legend">
    <h3>Layers</h3>
    <div className="legend-content">
      <ul>
        {geoJsonData.map((layer, index) => (
          <li key={index}>
            <label className="layer-name">
              <input
                type="checkbox"
                checked={selectedLayers[layer.name]}
                onChange={() => handleLayerChange(layer.name)}
              />
              {layer.name}
            </label>
            {/* Check if the layer has a categorization defined in categorizedLayers */}
            {categorizedLayers[layer.name] &&
              selectedLayers[layer.name] &&
              layerColorMappings[layer.name] && (
                <div className="layer-categories">
                  <ul>
                    {Object.entries(layerColorMappings[layer.name]).map(([type, color]) => (
                      <li key={type} className="layer-category">
                        <span
                          style={{
                            backgroundColor: color,
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            marginRight: "8px",
                          }}
                        ></span>
                        {type}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default LayerLegend;
