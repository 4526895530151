import * as arrow from "apache-arrow";
import * as aq from 'arquero';
import { useApi } from './ApiClient';
const BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

export const useFetchAggregatedData = () => {
  const { callApi } = useApi();  // Use callApi from useApi

  const fetchAggregatedData = async (apidata) => {
    try {
      const response = await callApi(`${BASE_URL}/api/data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(apidata),
      });

      // The response is a binary stream in Apache Arrow format
      const arrayBuffer = await response.arrayBuffer();

      // Convert the array buffer to an Arrow table
      const arrowTable = arrow.tableFromIPC(arrayBuffer);
      const agg_data = aq.fromArrow(arrowTable);

      // Return the table or process it further
      return agg_data.objects();

    } catch (error) {
      console.error('Error fetching aggregated data:', error);
      throw error;  // Re-throw the error so it can be handled higher up
    }
  };

  return { fetchAggregatedData };
};
