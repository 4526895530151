const visualizationModalData = {
    'bubble-chart': {
        xaxis: 'double',
        yaxis: 'double',
        zaxis: 'double'
    },

    'pie-chart': {
        xaxis: 'double',
        yaxis: null,
        zaxis: null
    },

    'line-chart': {
        xaxis: 'string',
        yaxis: 'double',
        zaxis: null
    },

    'box-plot': {
        xaxis: 'string',
        yaxis: 'double',
        zaxis: null
    },
};

export default visualizationModalData;