import React from 'react';
import './Toolbar.css';
import settingsIcon from '../../assets/darktheme/toolbar/settings.svg';
import themeIcon from '../../assets/darktheme/toolbar/theme.svg';
import notificationsIcon from '../../assets/darktheme/toolbar/notifications.svg';
import profileIcon from '../../assets/darktheme/toolbar/profile.svg';
import { useIsAuthenticated, useMsal } from '@azure/msal-react'; 
import { SignInButton, SignOutButton } from '../../components/auth/AuthButton';

const Toolbar = () => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();

  // Get the first name if authenticated, otherwise fallback to 'User'
  const firstName = isAuthenticated && accounts.length > 0
    ? accounts[0].name.split(' ')[0]  // Splitting full name and taking the first part
    : "";

  return (
    <div className="toolbar">
      {/* Authentication buttons and welcome message */}
      {isAuthenticated ? (
        <div className="auth-section">
          <img src={profileIcon} alt="profile" className="profile-button" />
          <span>Welcome, {firstName}!</span>
          <SignOutButton />
        </div>
      ) : (
        <SignInButton />
      )}

      {/* Other toolbar icons */}
      <img src={settingsIcon} alt="settings" className="settings-button" />
      <img src={notificationsIcon} alt="notifications" className="notification-button" />
      <img src={themeIcon} alt="mode" className="display-mode" />
    </div>
  );
};

export default Toolbar;
