import React, { useState } from 'react';
import { Box, Stack, Button } from '@mui/material';
import Dropdown from './Dropdown'; // Assuming Dropdown is a reusable component
import AddIcon from '@mui/icons-material/Add';

const DataSidePanel = () => {
  // State for the data source dropdown
  const [dataSource, setDataSource] = useState('Production Data');

  // Options for the dropdown
  const dataSourceOptions = ['Production Data'];

  const handleDataSourceChange = (event) => {
    setDataSource(event.target.value);
  };

  return (
    <Box
      sx={{
        width: '30%',
        minWidth: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        borderRight: '1px solid white', // Restore the vertical dividing line
      }}
    >
      {/* Data Source Section */}
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Stack direction="row" spacing={2}>
          <Dropdown
            label="Data Source"
            options={dataSourceOptions} // List of data sources
            value={dataSource}
            onChange={handleDataSourceChange}
          />
        </Stack>
      </Box>

      {/* Add Filters Button */}
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Button
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: 'black',
            color: 'white',
            border: '1px solid white',
            fontFamily: 'FoundryMonoline',
            '&:hover': {
              backgroundColor: 'gray',
              borderColor: 'white',
            },
          }}
          onClick={() => {
            /* Action for Add Filters button (currently does nothing) */
          }}
        >
          SAVE FILTERS
        </Button>
      </Box>
    </Box>
  );
};

export default DataSidePanel;