import React, { useState, useEffect } from 'react';
import { Box, Stack, IconButton, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Dropdown from './Dropdown';
import vizModalData from './visualizationModalData';
import { getDisplayName } from '../../utils/utils';  
import { getColumnName } from '../../utils/utils';

const VizSidePanel = ({ className, dataType, vizmodaldata, setVizmodaldata, setVizData}) => {
  // State to control selected values
  const [dataSource, setDataSource] = useState('Production Data');
  const [xAxis, setXAxis] = useState('');
  const [xAggregation, setXAggregation] = useState('');
  const [yAxis, setYAxis] = useState('');
  const [yAggregation, setYAggregation] = useState('');
  const [zAxis, setZAxis] = useState('');
  const [zAggregation, setZAggregation] = useState('');
  const [groupByFields, setGroupByFields] = useState(['']);

  // Options for axis and aggregations
  const aggregationOptions = ['Mean', 'Median'];
  const dataSourceOptions = ['Production Data']; // Only one option for now

  // Create columns for string and double types, map them to display names
  const columns = {
    string: [],
    double: []
  };

  // Loop through the column types and populate the respective arrays with display names
  for (const [column, type] of Object.entries(dataType.column_types)) {
    const displayName = getDisplayName(column);  // Get the display name for each column
    if (type === 'string') {
      columns.string.push(displayName);  // Use display names for string columns
    } else if (type === 'double') {
      columns.double.push(displayName);  // Use display names for double columns
    }
  }

  // Set default values for axes and groupBy fields (using vizmodaldata)
  useEffect(() => {
    if (vizmodaldata) {
      if (vizmodaldata.xaxis) {
        setXAxis(getDisplayName(vizmodaldata.xaxis));  // Set default for X-axis
        vizmodaldata.xAggregation? setXAggregation(getDisplayName(vizmodaldata.xAggregation)): setXAggregation('');  // Set default for X-aggregation
      }
      if (vizmodaldata.yaxis) {
        setYAxis(getDisplayName(vizmodaldata.yaxis));  // Set default for Y-axis
        vizmodaldata.yAggregation? setYAggregation(getDisplayName(vizmodaldata.yAggregation)): setYAggregation('');  // Set default for Y-aggregation
      }
      if (vizmodaldata.zaxis) {
        setZAxis(getDisplayName(vizmodaldata.zaxis));  // Set default for Z-axis
        vizmodaldata.zAggregation? setZAggregation(getDisplayName(vizmodaldata.zAggregation)): setZAggregation('');  // Set default for Z-aggregation
      }

      if (vizmodaldata.group_by) {
        const groupByDisplayNames = vizmodaldata.group_by.map(groupBy => getDisplayName(groupBy));

        // Check the number of groupBy fields and populate accordingly
        if (groupByDisplayNames.length === 1) {
          setGroupByFields([groupByDisplayNames[0]]);  // Set Group By 1
        } else if (groupByDisplayNames.length >= 2) {
          setGroupByFields([groupByDisplayNames[0], groupByDisplayNames[1]]);  // Set both Group By 1 and Group By 2
        }
      }
    }
  }, [vizmodaldata]);

  // When xAxis is updated, update vizmodaldata as well
  const handleXAxisChange = (value) => {
    setXAxis(value);
    
    // Update the xaxis in vizmodaldata
    setVizmodaldata((prevData) => ({
      ...prevData,
      xaxis: getColumnName(value),
    }));
  };

  const handleXAggregationChange = (value) => {
    setXAggregation(value);

    // Update the xAggregation in vizmodaldata
    setVizmodaldata((prevData) => ({
      ...prevData,
      xAggregation: getColumnName(value),
    }));
  };

  // When yAxis is updated, update vizmodaldata as well
  const handleYAxisChange = (value) => {
    setYAxis(value);
    
    // Update the xaxis in vizmodaldata
    setVizmodaldata((prevData) => ({
      ...prevData,
      yaxis: getColumnName(value),
    }));
  };

  const handleYAggregationChange = (value) => {
    setYAggregation(value);

    // Update the yAggregation in vizmodaldata
    setVizmodaldata((prevData) => ({
      ...prevData,
      yAggregation: getColumnName(value),
    }));
  };

  // When zAxis is updated, update vizmodaldata as well
  const handleZAxisChange = (value) => {
    setZAxis(value);

    // Update the xaxis in vizmodaldata
    setVizmodaldata((prevData) => ({
      ...prevData,
      zaxis: getColumnName(value),
    }));
  };

  const handleZAggregationChange = (value) => {
    setZAggregation(value);

    // Update the zAggregation in vizmodaldata
    setVizmodaldata((prevData) => ({
      ...prevData,
      zAggregation: getColumnName(value),
    }));
  };

  const handleGroupByChange = (index, value) => {
    const newGroupByFields = [...groupByFields];
    newGroupByFields[index] = value;
    setGroupByFields(newGroupByFields);

    // Update the group_by fields in vizmodaldata
    setVizmodaldata((prevData) => ({
      ...prevData,
      group_by: newGroupByFields.map((groupBy) => getColumnName(groupBy)),
    }));
  };

  // Add another Group By field (limit to 2)
  const addGroupByField = () => {
    if (groupByFields.length < 2) {
      setGroupByFields([...groupByFields, '']); // Add an empty string for a new group by field
    }
  };

  const updateVisualization = () => {
    // Update vizData with the selected values
    setVizData((prevData) => ({
      ...prevData,
      xaxis: getColumnName(xAxis),
      xAggregation: getColumnName(xAggregation),
      yaxis: getColumnName(yAxis),
      yAggregation: getColumnName(yAggregation),
      zaxis: getColumnName(zAxis),
      zAggregation: getColumnName(zAggregation),
      group_by: groupByFields.map((groupBy) => getColumnName(groupBy)),  // Update group_by fields
    }));
  };  

  return (
    <Box
      sx={{
        width: '30%',
        minWidth: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',  // Ensure space between options and button
        height: '100%',
        borderRight: '1px solid white',
      }}
    >
      {/* Visualization options - wrapped in a scrollable container */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: 2,
        }}
      >
        {/* Data Source Dropdown */}
        <Stack direction="row" spacing={2} mt={2}>
          <Dropdown
            label="Data Source"
            options={dataSourceOptions}  // Only "Production Data" option
            value={dataSource}
            onChange={(event) => setDataSource(event.target.value)}
          />
        </Stack>

        {/* Axis dropdowns with aggregation next to each */}
        {vizModalData[className.trim()].xaxis != null && (
          <Stack direction="row" spacing={2} mt={2}>
            {/* X-Axis and its Aggregation */}
            <Dropdown
              label="X-Axis"
              options={columns[vizModalData[className.trim()].xaxis]}
              value={xAxis}  // Set default value for X-Axis
              onChange={(event) => handleXAxisChange(event.target.value)}
            />
            
            {dataType.column_types[vizmodaldata.xaxis] === 'double' && (
            <Dropdown
              label="Aggregation"
              options={aggregationOptions}
              value={xAggregation}  // Set default value for X-Aggregation
              onChange={(event) => handleXAggregationChange(event.target.value)}
            />
            )}
          </Stack>
        )}

        {vizModalData[className.trim()].yaxis != null && (
          <Stack direction="row" spacing={2} mt={2}>
            {/* Y-Axis and its Aggregation */}
            <Dropdown
              label="Y-Axis"
              options={columns[vizModalData[className.trim()].yaxis]}
              value={yAxis}  // Set default value for Y-Axis
              onChange={(event) => handleYAxisChange(event.target.value)}
            />
            {dataType.column_types[vizmodaldata.yaxis] === 'double' && (
            <Dropdown
              label="Aggregation"
              options={aggregationOptions}
              value={yAggregation}  // Set default value for Y-Aggregation
              onChange={(event) => handleYAggregationChange(event.target.value)}
            />
            )}
          </Stack>
        )}

        {vizModalData[className.trim()].zaxis != null && (
          <Stack direction="row" spacing={2} mt={2}>
            {/* Z-Axis and its Aggregation */}
            <Dropdown
              label="Z-Axis"
              options={columns[vizModalData[className.trim()].zaxis]}
              value={zAxis}  // Set default value for Z-Axis
              onChange={(event) => handleZAxisChange(event.target.value)}
            />
            {dataType.column_types[vizmodaldata.zaxis] === 'double' && (
            <Dropdown
              label="Aggregation"
              options={aggregationOptions}
              value={zAggregation}  // Set default value for Z-Aggregation
              onChange={(event) => handleZAggregationChange(event.target.value)}
            />
            )}
          </Stack>
        )}

        {/* Group By Dropdowns */}
        <Box mt={2}>
          {groupByFields.map((groupByField, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Dropdown
                label={`Group By ${index + 1}`}
                options={columns.string}
                value={groupByField}
                onChange={(event) => handleGroupByChange(index, event.target.value)}
              />
            </Box>
          ))}

          {/* IconButton to add another Group By field */}
          <IconButton
            onClick={addGroupByField}
            sx={{
              color: 'white',
              mt: 1,
              border: '1px solid white',
              padding: 0.5,
              fontFamily: 'FoundryMonoline',
              // Disable the button if already 2 group by fields
              opacity: groupByFields.length >= 2 ? 0.5 : 1,
              pointerEvents: groupByFields.length >= 2 ? 'none' : 'auto'
            }}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      {/* Save Visualization Button - Fixed at the bottom */}
      <Box
        sx={{
          padding: 2,
          color: 'black',
          textAlign: 'center',
          fontFamily: 'FoundryMonoline',   
        }}
      >
        <Button
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: 'black',
            color: 'white',
            border: '1px solid white',
            fontFamily: 'FoundryMonoline',
            '&:hover': {
              backgroundColor: 'gray',
              borderColor: 'white',
            }
          }}
          onClick={() => updateVisualization()}
        >
          Save Visualization
        </Button>
      </Box>
    </Box>
  );
};

export default VizSidePanel;
