import { useApi } from './ApiClient';
const BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

export const useFetchDataType = () => {
  const { callApi } = useApi();  // Use callApi from useApi

  const fetchDataType = async () => {
    try {
      // Call the API endpoint to fetch data types
      const response = await callApi(`${BASE_URL}/api/columns/types`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      // Extract the JSON data from the response
      const datatype = await response.json();  // Process the response as JSON
      return datatype;  // Return only the JSON data

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      return null;
    }
  };

  return { fetchDataType };
};