const panelSizes = {
    'default': {
        'map': { width: 5, height: 6 },
        'bubble-chart': { width: 3, height: 3},
        'pie-chart': { width: 2, height: 3 },
        'line-chart': { width: 5, height: 3 },
        'box-plot': { width: 5, height: 3 },
        'data-viewer': { width: 5, height: 3 }
    },
    'responsive': {
        'map': { width: 2, height: 2 },
        'bubble-chart': { width: 1, height: 1 },
        'pie-chart': { width: 1, height: 1 },
        'line-chart': { width: 1, height: 1 },
        'box-plot': { width: 1, height: 1 },
        'data-viewer': { width: 1, height: 2 }
    }
};
  
export default panelSizes;
  