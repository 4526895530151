import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const Dropdown = ({ label, options, value, onChange }) => {
  return (
    <FormControl fullWidth>
      <InputLabel
        shrink
        sx={{
          color: 'white',
          top: '-3px',
          left: '-7px',
          '&.Mui-focused': {
            color: 'white', // Keep white color when focused
          },
        }}
      >
        {label} {/* Label passed as a prop */}
      </InputLabel>
      <Select
        value={value} // Pass the controlled value here
        onChange={onChange} // Pass the onChange handler here
        sx={{
          color: 'white',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'white', // White border
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white', // White border on hover
          },
          '.MuiSvgIcon-root': {
            color: 'white', // White dropdown arrow
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white', // White border on focus
          },
        }}
      >
        {/* Render options dynamically */}
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
